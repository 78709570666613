import pino from "pino";

interface StrictLogFn {
  (obj: unknown, msg?: string): void;
  (msg: string): void;
}

interface Logger extends pino.Logger {
  debug: StrictLogFn;
  error: StrictLogFn;
  fatal: StrictLogFn;
  info: StrictLogFn;
  trace: StrictLogFn;
  warn: StrictLogFn;
}

const IS_DEV = process.env.NODE_ENV !== "production";
const IS_EDGE = process.env.NEXT_RUNTIME === "edge";

const TRANSPORT_CONFIG = IS_DEV
  ? {
      target: "pino-pretty",
      options: {
        colorize: true,
      },
    }
  : undefined;

const LEVEL_FORMATTER = {
  level: (label: string) => ({ level: label }),
};

const logger: Logger = pino({
  level: IS_DEV ? "debug" : "info",
  errorKey: "error",
  messageKey: "message",
  formatters: LEVEL_FORMATTER,
  transport: TRANSPORT_CONFIG,
  browser: {
    serialize: true,
    formatters: LEVEL_FORMATTER,
    write: (o) => {
      // Only for middleware
      if (IS_EDGE) {
        // eslint-disable-next-line no-console
        console.log(JSON.stringify({ ...o, origin: "middleware" }));
      }
    },
    transmit: {
      // Only for web
      send(_level, logEvent) {
        if (!IS_EDGE) {
          fetch("/api/log", {
            method: "POST",
            body: JSON.stringify(logEvent),
          });
        }
      },
    },
  },
});

export { type Logger };

export default logger;
